import type { Community, Role } from '@/types/global-types';
import { ROLE_SUPERADMIN, type UserRole } from '@/stores/global-constants';
function getTokenDataFromLocalStorage() {
  // get the token from local storage
  const userToken = localStorage.getItem('token');
  if (!userToken) return null;
  // decode the token and return the user data
  return JSON.parse(atob(userToken.split('.')[1]));
}
function getAccessRolesFromTokenData() {
  const token = getTokenDataFromLocalStorage();
  if (!token) return [];
  const roles = Array.isArray(token.CommunityRole) ? token.CommunityRole : [token.CommunityRole];
  const accessRoles = [...new Set(roles.map((role: Role) => role.Role))];
  return accessRoles;
}

export default {
  /**
   * Check local storage for a communityId and return it if it exists, otherwise return null
   */
  getSelectedCommunityId: () => {
    return localStorage.community ? localStorage.community.replaceAll('"', '') : null;
  },
  /**
   * Used when no community has been found in local storage or state and we need to select a default community.
   * Admin users have a community ID of 'xk', so if that's the case, select it by default.
   * Otherwise, select the first community in the list.
   */
  getDefaultCommunityId: (communities: Community[]) => {
    const index = communities.findIndex((community) => community.id == 'xk');
    if (index > -1) return 'xk';
    else return communities[0].id;
  },

  selectedCommunityIdIsInAvailableCommunities: (
    availableCommunities: Community[],
    selectedCommunityId: string | null,
  ) => {
    if (!availableCommunities || !selectedCommunityId) return false;
    return availableCommunities.some((community) => community.id === selectedCommunityId);
  },

  getTokenData: getTokenDataFromLocalStorage,

  getAccessRoles: getAccessRolesFromTokenData,

  // checks user roles in the token
  isUserPermitted: (rolesToPermit: Array<UserRole>) => {
    const tokenData = getTokenDataFromLocalStorage();
    if (!tokenData) return false;
    const currentUserRoles = getAccessRolesFromTokenData();
    let permittedRoles = [...ROLE_SUPERADMIN];
    if (rolesToPermit) {
      if (Array.isArray(rolesToPermit)) {
        permittedRoles = [...permittedRoles, ...rolesToPermit];
      } else {
        permittedRoles.push(rolesToPermit);
      }
    }
    return (
      permittedRoles.includes(tokenData.CommunityRole?.Role) ||
      permittedRoles.some((e: string) => currentUserRoles.includes(e))
    );
  },

  /**
   * aggregatedCommunitiesIds is an array of community ids, initially added for the low-frequency chart
   */
  getAggregatedCommunityIdsFromLocalStorage: () => {
    return localStorage.aggregatedCommunities ? JSON.parse(localStorage.aggregatedCommunities) : [];
  },
  updateAggregatedCommunityIdsInLocalStorage: (communityIds: string[]) => {
    localStorage.setItem('aggregatedCommunities', JSON.stringify(communityIds));
  },

  /**
   * Get the name of a community from its ID
   */
  getCommunityNameFromId: (availableCommunities: Community[], communityId: string) => {
    return availableCommunities.find((comm) => comm.id === communityId)?.name ?? '';
  },
};
